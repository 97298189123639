<template>
  <div></div>
</template>

<script>
import axios from "axios";
import api from "../../request/http"

export default {
  name: "Index",
  created() {
    let geturl = window.location.href
    let getqyinfo = geturl.split('?')[1]
    console.log(geturl)
    console.log(getqyinfo)
    this.getcode(getqyinfo)
  },
  methods: {
    getcode(getqyinfo) {
      // this.$router.replace({path: 'Details', query: {userId: "1696465038694420481"}});
      const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      // const local = location.protocol + '//' + location.host + "/wx/?" + getqyinfo;
      const local = "https://smxxcx.slyxkj.com/?" + getqyinfo;
      let redirect_uri = encodeURIComponent(local) //回调的地址要编码
      console.log(redirect_uri)
      if (code == null || code === '') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx428cfc06614974ef&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        console.log(getqyinfo)
        // axios.post('https://shezhen.yuanjiwei.cn/api/app/wxCode/?'+getqyinfo,
        const id = getqyinfo.split("&")[0]
        console.log(id)
        axios.get("https://smxxcx.slyxkj.com/jeecgboot/api/app/getUserList/?"+id).then((result) => {
          const userid = result.data.result;
          console.log(result);
          console.log('请求成功');
          console.log(userid);
          if (userid == null || userid === "" || userid === '') {
            this.$router.replace("error");
          } else {
            console.log('请求成功');
            this.$router.replace({path: 'Details', query: {userId: id}});
          }
        }).catch(error => {
          this.$router.replace("error");
        });
      }
    },
    getUrlParam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  }
}
</script>

<style scoped>

</style>
