<template>
  <div>
    <div class="glossoscopy">
      <div class="glossoscopyList">
        <div class="commentsItem">

        <div class="assessment">
          <img
              :src="ossOriImgUrl"
              alt=""
          />
          <div class="col9 pt30">舌面原图矫正图</div>
        </div>

        <div class="assessment">
          <img
              :src="ossSplitImgUrl"
              alt=""
          />
          <div class="col9 pt30">舌面分割图</div>
        </div>

        <div class="assessment">
          <img
              :src="ossSplitBackImgUrl"
              alt=""
          />

          <div class="col9 pt30">舌面原图矫正图</div>
        </div>
        </div>
        <div class="diagnostic_results_view">
          <span>诊断结果：{{ diagnostic_results }}</span>
        </div>

        <span class="fz24" v-html="veinDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <!-- <img src="../../assets/images/assessment1.png" class="glossoscopytex" alt=""> -->
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment1.png">
          <span class="glossoscopytitle">津液</span>
        </div>
        <div class="glossoscopytitle_two">{{ bodyfluidNames }}</div>
        <span class="fz24" v-html="bodyfluidDescribe"
        ></span
        >
      </div>
      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment2.png">
          <span class="glossoscopytitle">苔色</span>
        </div>
        <div class="glossoscopytitle_two">{{ colorOfMossNames }}</div>
        <span class="fz24" v-html="colorOfMossDescribe"></span
        >
      </div>
      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment3.png">
          <span class="glossoscopytitle">舌色</span>
        </div>
        <div class="glossoscopytitle_two">{{ colorOfTongueNames }}</div>
        <span class="fz24" v-html="colorOfTongueDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment4.png">
          <span class="glossoscopytitle">苔质</span>
        </div>
        <div class="glossoscopytitle_two">{{ mossNames }}</div>
        <span class="fz24" v-html="mossDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment5.png">
          <span class="glossoscopytitle">舌形</span>
        </div>
        <div class="glossoscopytitle_two">{{ shapeOfTongueNames }}</div>
        <span class="fz24" v-html="shapeOfTongueDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment6.png">
          <span class="glossoscopytitle">络脉</span>
        </div>
        <div class="glossoscopytitle_two">{{ veinNames }}</div>
        <span class="fz24" v-html="veinDescribe"/>
      </div>








      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment10.png">
          <span class="glossoscopytitle">易患病症</span>
        </div>
        <div v-for="(care,cares) in predisposition" :key="cares">
          <div class="explain">说明</div>
          <span class="fz24">{{ care.message }}</span>
          <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
            <div class="Recuperation">
              <img :src="item.photo" alt="">
              <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
            </div>
          </div>
        </div>
      </div>





      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment12.png">
          <span class="glossoscopytitle">主要表现</span>
        </div>

        <div v-for="(care,cares) in main_performance" :key="cares">
          <div class="explain">说明</div>
          <span class="fz24">{{ care.message }}</span>
          <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
            <div class="Recuperation">
              <img :src="item.photo" alt="">
              <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
            </div>
          </div>
        </div>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment13.png">
          <span class="glossoscopytitle">发生原因</span>
        </div>

        <div v-for="(care,cares) in occur_reason" :key="cares">
          <div class="explain">说明</div>
          <span class="fz24">{{ care.message }}</span>
          <div v-for="(item,itemvalue) in care.items" :key="itemvalue">
            <div class="Recuperation">
              <img :src="item.photo" alt="">
              <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.desc"/>
                        </span>
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Healthy",
  data() {
    return {
      bodyfluidNames: "",
      bodyfluidDescribe: "",
      colorOfMossNames: "",
      colorOfMossDescribe: "",
      colorOfTongueNames: "",
      colorOfTongueDescribe: "",
      mossNames: "",
      mossDescribe: "",
      shapeOfTongueNames: "",
      shapeOfTongueDescribe: "",
      veinNames: "",
      veinDescribe: "",
      constitutionNames: "",
      constitutionDescribe: "",
      singleConfigName: "",
      ossOriImgUrl: "",
      ossSplitImgUrl: "",
      ossSplitBackImgUrl: "",
      diagnostic_results: "",
      br:"",
      loading: false,
      finished: true,
      sports_health_care: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      diet_rehabilitation: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      predisposition: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      massage_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      main_performance: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      occur_reason: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      drug_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      recuperates: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
    };
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://smxxcx.slyxkj.com/jeecgboot/api/app/details?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/details?" + userId
      this.br = "<br/>"
      axios.post(url)
          .then((result) => {
            console.log(result);
            let details = result.data.result
            this.bodyfluidNames = details.bodyfluidnames
            this.bodyfluidDescribe = details.bodyfluiddescribe
            this.colorOfMossNames = details.colorofmossnames
            this.colorOfMossDescribe = details.colorofmossdescribe
            this.colorOfTongueNames = details.coloroftonguenames
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.mossNames = details.mossnames
            this.mossDescribe = details.mossdescribe
            this.shapeOfTongueNames = details.shapeoftonguenames
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.veinNames = details.veinnames
            this.veinDescribe = details.veindescribe
            this.constitutionNames = details.constitutionnames
            this.constitutionDescribe = details.constitutiondescribe
            this.singleConfigName = details.singleconfigname

            this.ossOriImgUrl = details.ossoriimgurl
            this.ossSplitImgUrl = details.osssplitimgurl
            this.ossSplitBackImgUrl = details.osssplitbackimgurl
            let json = JSON.parse(details.treatplanjson)
            console.log(json);
            this.diagnostic_results = json[0].diagnostic_results
            if(this.diagnostic_results === null || this.diagnostic_results===""){
              this.diagnostic_results = this.singleConfigName
            }
            this.sports_health_care = json[0].sports_health_care
            this.diet_rehabilitation = json[0].diet_rehabilitation
            this.predisposition = json[0].predisposition
            this.massage_health_care = json[0].massage_health_care
            this.main_performance = json[0].main_performance
            this.occur_reason = json[0].occur_reason
            this.drug_health_care = json[0].drug_health_care
            this.recuperates = json[0].recuperates
          }).catch(error => {
        this.$router.replace("error")
      });
    },
  },
};
</script>
<style scoped>
.pl30 {
  padding-left: 30px;
}

.fz28 {
  color: #000000;
  font-size: 30px;
}

.fz20 {
  font-size: 20px;
  color: #999999;
}

.p15 {
  padding: 10px;
}

.Recuperation {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(245, 245, 245, 1);
  border-radius: 15px;
}

.Recuperation > img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 25px;
}

.explain {
  font-size: 28px;
  color: rgba(56, 56, 56, 1);
  padding: 20px 0;
}

.glossoscopyimg > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.col9 {
  color: #999999;
}

.df {
  display: flex;
}

.jub {
  justify-content: space-between;
}

.pt30 {
  padding-top: 30px;
}

.coltt {
  color: rgba(0, 186, 173, 1);
}

.al {
  align-items: center;
}

.fz25 {
  font-size: 25px;
}

.fz24 {
  font-size: 26px;
  color: #999999;
  line-height: 45px;
  padding-top: 30px;
}

.glossoscopytitle_two {
  font-size: 40px;
  color: rgba(0, 186, 173, 1);
  font-weight: 650;
  text-align: center;
  /* border-bottom: 6px solid rgba(0, 186, 173, 1); */
  /* width: 50px; */
  margin: 0 auto 30px auto;
  /* padding-bottom: 45px; */
}

.glossoscopytitle_one {
  display: flex;
  align-items: center;
}

.glossoscopytex {
  width: 30px;
  height: 30px;
  /* background-color: #000000; */
  /* border-radius: 50%; */
  display: block;
  margin-right: 10px;
}

.glossoscopyList {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 30px;
  margin-bottom: 30px;
}

.glossoscopytitle {
  font-size: 26px;
  color: #000000;
  font-weight: 650;
}

.glossoscopy {
  padding: 0.4rem;
  background: rgba(0, 186, 173, 1);
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.assessment > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

.diagnostic_results_view{
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.diagnostic_results_view span{
  padding: 8px 24px;
  border-radius: 38px;
  background: rgba(217, 250, 248, 1);

  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(0, 186, 173, 1);
  vertical-align: top;
}
</style>
