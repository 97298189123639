<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>身心状况</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/shenxinjiank.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>健康指数</span><span>{{health}}分</span><span>/100</span><span>{{healthIndex}}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="jiankang" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>情绪</span><span>{{emotionIndex}}分</span><span>/100</span><span>{{emotion}}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="qingxu" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>脑力</span><span>{{mentalIndex}}分</span><span>/100</span><span>{{mental}}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="naoli" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>反应力</span><span>{{reactionIndex}}分</span><span>/100</span><span>{{ reaction }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="fanyingli" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>记忆力</span><span>{{memoryIndex}}分</span><span>/100</span><span>{{memory}}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="jiyili" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>氧饱和</span><span>{{saturationIndex}}分</span><span>/100</span><span>{{saturation}}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="yangbohe" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>体肉酸碱</span><span>{{acidBaseIndex}}分</span><span>/100</span><span>{{ acidBase }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="suanjian" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item">
        <div class="view_content_bg_item_title">
          <span>体内水分</span><span>{{dewIndex}}分</span><span>/100</span><span>{{ dew }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="shuifen" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>

import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "Healthymind",
  components: {view_bottom},
  data() {
    return {
      healthIndex:"",
      health:"",
      emotion:"",
      emotionIndex:"",
      mental:"",
      mentalIndex:"",
      reaction:"",
      reactionIndex:"",
      memory:"",
      memoryIndex:"",
      saturation:"",
      saturationIndex:"",
      acidBase:"",
      acidBaseIndex:"",
      dewIndex:"",
      dew:"",

    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://smxxcx.slyxkj.com/jeecgboot/api/app/getGeren?" + userId
      this.br = "<br/>"
      axios.get(url)
          .then((result) => {
            this.healthIndex = result.data.result.healthIndex
            this.emotionIndex = result.data.result.emotionIndex
            this.emotion = result.data.result.emotion
            this.mental = result.data.result.mental
            this.mentalIndex = result.data.result.mentalIndex
            this.reaction = result.data.result.reaction
            this.reactionIndex = result.data.result.reactionIndex
            this.memory = result.data.result.memory
            this.memoryIndex = result.data.result.memoryIndex
            this.saturation = result.data.result.saturation
            this.saturationIndex = result.data.result.saturationIndex
            this.acidBase = result.data.result.acidBase
            this.acidBaseIndex = result.data.result.acidBaseIndex
            this.dewIndex = result.data.result.dewIndex
            this.dew = result.data.result.dew
            if(this.healthIndex > 90){
              this.health = "5"
            }else if(this.healthIndex > 80){
              this.health = "4"
            }else{
              this.health = "3"
            }
            this.initBarChart("jiankang",this.healthIndex)
            this.initBarChart("qingxu",this.emotion)
            this.initBarChart("naoli",this.mental)
            this.initBarChart("fanyingli",this.reaction)
            this.initBarChart("jiyili",this.memory)
            this.initBarChart("yangbohe",this.saturation)
            this.initBarChart("suanjian",this.acidBase)
            this.initBarChart("shuifen",this.dew)
          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view,count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
